import "core-js/stable";
import "regenerator-runtime/runtime";

require('bootstrap');

const images = require.context('../vendor/paper-dashboard-2-pro-html-v2.0.1/assets/img', true);
const imagePath = (name) => images(name, true);

require('corejs-typeahead');
import Bloodhound from 'corejs-typeahead/dist/bloodhound';
global.Bloodhound = Bloodhound

require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/bootstrap-datetimepicker');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/bootstrap-notify');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/bootstrap-selectpicker');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/bootstrap-switch');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/bootstrap-tagsinput');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/fullcalendar.min');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/jasny-bootstrap.min');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/jquery-jvectormap');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/jquery.bootstrap-wizard');

// require('imports-loader?define=>false!vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/jquery.dataTables.min');
// import dt from 'imports-loader?define=>false!vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/jquery.dataTables.min';
// dt(window, $);

require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/jquery.validate.min');
require('vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/perfect-scrollbar.jquery.min');

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
window.ChartDataLabels = ChartDataLabels;
Chart.plugins.unregister(ChartDataLabels);

import noUiSlider from 'vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/nouislider.min';
window.noUiSlider = noUiSlider;

import swal from "vendor/paper-dashboard-2-pro-html-v2.0.1/assets/js/plugins/sweetalert2.min";
window.swal = swal;

require('src/paper-dashboard');

import 'stylesheets/paper-dashboard';
import 'stylesheets/font-awesome';
import 'vendor/paper-dashboard-2-pro-html-v2.0.1/assets/css/bootstrap.min';
import 'vendor/paper-dashboard-2-pro-html-v2.0.1/assets/css/paper-dashboard';
